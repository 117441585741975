export const isClientSide = typeof window !== "undefined";
export const isLocal = process.env.NODE_ENV === "development";

export enum ENV {
  LOCAL = "local",
  DEVELOPMENT = "development",
  UAT = "uat",
  PRODUCTION = "production",
}

export const getEnv = () => {
  if (isClientSide) {
    const hostname = window.location.hostname;
    // if (hostname.startsWith("")) return ENV.DEVELOPMENT;
    if (hostname.startsWith("uat2.timable.com")) return ENV.UAT;
    if (hostname.startsWith("timable.com")) return ENV.PRODUCTION;
    return ENV.LOCAL;
  } else {
    return (process.env.ENVIRONMENT as ENV) || ENV.LOCAL;
  }
};
