import * as Sentry from "@sentry/browser";

import { ENV, getEnv } from "./env";

const options: Partial<Parameters<typeof Sentry.init>["0"]> = {
  enabled: [ENV.UAT, ENV.PRODUCTION].includes(getEnv()),
  environment: getEnv(),
};

const monitor = {
  setContext: Sentry.setContext,
  setUser: Sentry.setUser,
  options,
};

export default monitor;
